import { client } from 'services/contentfulClient'
import { getDateString } from 'utils'

export const fetchArticles = async (filters, search, offset = 0, limit = 6) => {
    try {
        let query = {
            order: '-fields.datePosted',
            content_type: 'article',
            limit,
            skip: (limit * offset)
        }

        if (search) {
            query = {
                ...query,
                'query': search,
            }
        }

        if (filters) {
            if (filters.articleType !== 'All Articles') {
                query = {
                    ...query,
                    'fields.type.sys.contentType.sys.id': 'articleType',
                    'fields.type.fields.title[match]': filters.articleType
                }
            }

            if (filters.articleTopic !== 'All Topics') {
                query = {
                    ...query,
                    'fields.topic.sys.contentType.sys.id': 'articleTopic',
                    'fields.topic.fields.title[match]': filters.articleTopic
                }
            }

            if (filters.articleDate !== 'Latest') {
                query = {
                    ...query,
                    'fields.datePosted[gte]': new Date(filters.articleDate).toISOString(),
                    'fields.datePosted[lt]': new Date((parseInt(filters.articleDate) + 1).toString()).toISOString()
                }
            }
        }

        const response = await client.getEntries(query)

        if (response) {
            return response.items.map( (item, i) => {
                return {
                    title: item.fields.title,
                    imageUrl: item.fields.articleImage ? item.fields.articleImage.fields.file.url : '',
                    type: item.fields.type.fields.title,
                    topic: item.fields.topic.fields.title,
                    date: getDateString(new Date(item.fields.datePosted)),
                    linkUrl: item.fields.linkUrl,
                    linkPublisher: item.fields.linkPublisher
                }
            })
        }

        return []
    } catch (err) {
        console.error(err)
    }
}

export const fetchArticleYears = async () => {
    try {
        const response = await client.getEntries({
            content_type: 'article',
            select: 'fields.datePosted'
        })

        if (response) {
            return Array.from(new Set(response.items.map(({fields}) => (new Date(fields.datePosted)).getFullYear()))).sort((a, b) => a < b ? 1 : -1)
        }

        return []
    } catch (err) {
        console.error(err)
    }
}

export const fetchTopics = async () => {
    try {
        const response = await client.getEntries({
            content_type: 'articleTopic',
        })

        if (response) {
            return response.items.map(({fields}) => fields.title)
        }

        return []
    } catch (err) {
        console.error(err)
    }
}

export const fetchHomePage = async () => {
    try {
        const data = {
            quote: null,
            heroArticle: null,
            featuredArticles: [],
            collageImages: [],
            collageStatistic: null
        }

        const { fields: { collage, collageStatistic, hero, heroArticles, quoteAuthor, quoteText, quoteAuthorOccupation } } = (await client.getEntries({
            content_type: 'landingPageContent'
        })).items[0]

        data.quote = {
            author: quoteAuthor,
            text: quoteText,
            authorOccupation: quoteAuthorOccupation || null
        }

        if (hero) {
            data.heroArticle = {
                id: hero.sys.id,
                title: hero.fields.title,
                linkUrl: hero.fields.linkUrl,
                linkPublisher: hero.fields.linkPublisher,
                imageUrl: hero.fields.articleImage?.fields.file.url
            }
        }

        if (heroArticles) {
            data.featuredArticles = heroArticles.map(({sys, fields}) => {
                const imageUrl = fields.articleImage?.fields.file.url

                return {
                    id: sys.id,
                    title: fields.title,
                    linkUrl: fields.linkUrl,
                    linkPublisher: fields.linkPublisher,
                    datePosted: getDateString(new Date(fields.datePosted)),
                    imageUrl,
                    quote: fields.articleQuote,
                    topic: fields.topic?.fields?.title,
                    type: fields.type?.fields?.title
                }
            })
        }

        if (collage) {
            data.collageImages = [
                {...collage.fields.image1},
                {...collage.fields.image2},
                {...collage.fields.image3},
                {...collage.fields.image4},
                {...collage.fields.image5}
            ]
        }

        if (collageStatistic) {
            data.collageStatistic = collageStatistic
        }

        return data
    } catch(err) {
        console.error(err)
    }
}

export const fetchAboutPage = async () => {
    try {
        const { fields: { awards, leadershipTeam, timeline, ourStory, reachOutText, reachOutLink } } = (await client.getEntries({
            content_type: 'aboutPageContent',
            include: 2
        })).items[0]

        return {
            awards: awards.map(({fields, sys}) => ({...fields, id: sys.id})),
            leadershipTeam: leadershipTeam.map(({fields, sys}) => ({...fields, id: sys.id})),
            ourStory,
            contact: {
                reachOutText,
                reachOutLink
            },
            timeline: timeline.map(({fields, sys}) => ({...fields, id: sys.id})).sort((a, b) => {
                if (parseInt(a.year) < parseInt(b.year)) return -1;
                if (parseInt(a.year) > parseInt(b.year)) return 1;
                return 0;
            })
        }
    } catch (err) {
        console.error(err)
    }
}
