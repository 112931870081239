import arrowUp from 'assets/svg/arrow-up.svg'

export default function ArticleThirdCard({article}) {
    const {title, imageUrl, topic, linkUrl, linkPublisher, date} = article

    return (
        <article className="article-third-card">
            <div className="article-third-card__image" onClick={() => window.open(linkUrl, '_blank').focus()}>
                <img alt={title} src={imageUrl} />
            </div>
            <div className="article-third-card__content">
                <div className="eyebrow">{topic} | {date}</div>
                <div className="news-title" onClick={() => window.open(linkUrl, '_blank').focus()}>{title}</div>
                <a target="_blank" rel="noreferrer" className="news-link red-underline" href={linkUrl}>{linkPublisher} <img alt="Arrow" className="link-arrow" src={arrowUp}/></a>
            </div>
        </article>
    )
}
