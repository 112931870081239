import { useState, useEffect, useRef, useMemo } from 'react'
import { useQuery } from 'react-query'
import { withRouter } from 'react-router-dom'
import useRouterQuery from 'hooks/useRouterQuery'
import { fetchArticles } from 'helpers/newsroom'

import ArticleThirdCard from 'components/ArticleThirdCard'
import PressBar from 'components/press/PressBar'

import autoLoadImage from 'assets/img/auto-load.png'

function PressResults({location}) {
    const [articles, setArticles] = useState([])

    const offset = useRef(0)
    // implement infinite scrolling with intersection observer
    const bottomBoundaryRef = useRef(null)

    const RouterQuery = useRouterQuery()
    const articleTypeQuery = RouterQuery.get('articleType')
    const articleTopicQuery = RouterQuery.get('articleTopic')
    const articleDateQuery = RouterQuery.get('articleDate')
    const searchQuery = RouterQuery.get('search')

    const filters = useMemo(() => ({
        articleType: articleTypeQuery || 'Featured Articles',
        articleTopic: articleTopicQuery || 'All Topics',
        articleDate: articleDateQuery || 'Latest'
    }), [articleTypeQuery, articleTopicQuery, articleDateQuery])

    const { isError, data, error } = useQuery(['pressArticles', articleTypeQuery, articleTopicQuery, articleDateQuery, searchQuery], () => fetchArticles(filters, searchQuery))


    if (isError) {
        console.error(error.message)
    }

    useEffect(() => {
        setArticles(data)
    }, [data])

    useEffect(() => {
        const bottomBoundary = bottomBoundaryRef.current
        const Observer = new IntersectionObserver(entries => {
            entries.forEach(en => {
                if (en.intersectionRatio > 0) {
                (async () => {
                    const newArticles = await fetchArticles(filters, searchQuery, offset.current + 1)
                    if (newArticles.length > 0) {
                        bottomBoundary.classList.add('show')
                        setTimeout(() => {
                            offset.current += 1
                            setArticles((state) => [...state, ...newArticles])
                            bottomBoundary.classList.remove('show')
                        }, 300);
                    }
                })()
                }
            });
        })

        if (bottomBoundary) {
            Observer.observe(bottomBoundary);
        }

        return () => {
            if (bottomBoundary) {
                Observer.unobserve(bottomBoundary);
            }
        }
    }, [filters, searchQuery, bottomBoundaryRef]);

    useEffect(() => {
        offset.current = 0;
    }, [filters, searchQuery])


    return (
        <div className="press">
            {
                !location.search &&
            <div className="container">
                <h2 className="press__hero">MasterClass in the Press</h2>
            </div>
            }
            <PressBar/>
            <section className="container press__articles">
                { articles && articles.length === 0 && (
                    <div className="no-results">
                        <span>Results not found.</span>
                    </div>
                )}
                { articles && location.search && articles.length > 0 && (<span className="results-indicator">{articles.length} Found Articles</span>)}
                { articles &&
                <div className="grid">
                    {articles && articles.map((article, i) => (
                        <ArticleThirdCard key={`article-1/3-${i + 1}`} article={article} />
                    ))}
                </div>
                }
            </section>

            <div id='page-bottom-boundary' className="page-bottom-boundary" ref={bottomBoundaryRef}>
                <img alt="press articles load indicator" src={autoLoadImage}/>
            </div>
        </div>
    )
}

export default withRouter(PressResults)
