import arrowUp from '../assets/svg/arrow-up.svg'

export default function ArticleHalfCard({article}) {
    return (
        <article className="article-card-half">
            { !article.quote &&
                <div className="article-card-half__image" onClick={() => window.open(article.linkUrl, '_blank').focus()}>
                    <img alt={article.title} src={article.imageUrl} />
                </div>
            }
            <div className={`article-card-half__content ${article.quote ? 'article-quote' : ''}`}>
                { article.quote ? (
                    <h4 className="quote red-quotations" onClick={() => window.open(article.linkUrl, '_blank').focus()}>
                        <span className="startquote"/>{article.quote}<span className="endquote"/>
                    </h4>
                ) : <h4 className="heading" onClick={() => window.open(article.linkUrl, '_blank').focus()}>{article.title}</h4> }

                <a target="_blank" rel="noreferrer" href={article.linkUrl} className="news-link red-underline muted-link">{article.linkPublisher} <img alt="arrow-link" className="link-arrow" src={arrowUp}/></a>
            </div>
        </article>
    )
}
