import { useState, useEffect } from 'react'
import mobileNavExitArrow from 'assets/img/mobile-nav-arrow.png'


export default function Recognition({awards}) {
    const [currentAward, setCurrentAward] = useState(null)

    useEffect(() => {
        function checkScreenWidth() {
            if (window.innerWidth >= 768) {
                setCurrentAward(null)
            }
        }

        window.addEventListener('resize', checkScreenWidth)
        return () => window.removeEventListener('resize', checkScreenWidth)
    }, [])

    useEffect(() => {
        if (currentAward) {
            document.body.style.top = `-${window.scrollY}px`
            document.body.style.position = 'fixed';
        } else {
            const scrollY = document.body.style.top
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1)
        }
    }, [currentAward])

    return (
        <>
            <section id="Recognition" className="recognition">
                <div className="recognition__title">
                    <hr/>
                    <h4 className="title">Our Recognitions and Awards</h4>
                </div>
                <div className="recognition__description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                </div>
                <div className="recognition__awards">
                    { awards && awards.map(award => (
                        <div className="recognition__award" key={award.id} onClick={() => window.innerWidth < 768 && setCurrentAward(award)}>
                            {award.awardLogo ? (
                                <div className="recognition__award-image award-logo">
                                    <img alt={award.awardLogo?.fields.title} src={award.awardLogo?.fields.file.url}/>
                                </div>
                            ) : (
                                <div className="recognition__award-image award-background">
                                    <img alt={award.awardImage?.fields.title} src={award.awardImage?.fields.file.url}/>
                                </div>
                            )}
                            <div className="recognition__award-content">
                                <div className="award-statistics">
                                    { award.yearWon && (
                                        <div className="award-year-won">
                                            <div className="award-statistics__header">Year Won</div>
                                            <span>{ award.yearWon }</span>
                                        </div>
                                    )}
                                    { award.category && (
                                        <div className="award-category">
                                            <div className="award-statistics__header">Category</div>
                                            <span>{ award.category }</span>
                                        </div>
                                    )}
                                    { award.specificClassExecutive && (
                                        <div className="award-executive">
                                            <div className="award-statistics__header">
                                                Specific Class / Executive
                                            </div>
                                            { award.specificClassExecutive }
                                        </div>
                                    )}
                                </div>
                                <h5 className="award-title">{award.awardTitle}</h5>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <div className={`mobile-menu ${currentAward ? 'active' : ''}`}>
                {currentAward && (
                <div className="mobile-menu__wrapper">
                    <div className="nav-menu-exit" onClick={() => setCurrentAward(null)}>
                        <img alt="Exit" src={mobileNavExitArrow} />
                    </div>
                    <div className="recognition__mobile-menu">
                        <h5 className="award-title">{currentAward.awardTitle}</h5>
                        { currentAward.yearWon && (
                            <div className="award-year-won">
                                <div className="award-statistics__header">Year Won</div>
                                <span>{ currentAward.yearWon }</span>
                            </div>
                        )}
                        { currentAward.category && (
                            <div className="award-category">
                                <div className="award-statistics__header">Category</div>
                                <span>{ currentAward.category }</span>
                            </div>
                        )}
                        { currentAward.specificClassExecutive && (
                            <div className="award-executive">
                                <div className="award-statistics__header">
                                    Specific Class / Executive
                                </div>
                                { currentAward.specificClassExecutive }
                            </div>
                        )}
                    </div>
                </div>
                )}
            </div>
        </>
    )
}
