export function forceDownload(url, fileName){
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url, true);
    xhr.responseType = "blob";
    xhr.onload = function(){
        var urlCreator = window.URL || window.webkitURL;
        var imageUrl = urlCreator.createObjectURL(this.response);
        var tag = document.createElement('a');
        tag.href = imageUrl;
        tag.download = fileName;
        document.body.appendChild(tag);
        tag.click();
        document.body.removeChild(tag);
    }
    xhr.send();
}

export function fallbackCopyTextToClipboard(text) {
    let successful;
    var textArea = document.createElement("textarea");
    textArea.value = text;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.position = "fixed";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      successful = document.execCommand('copy');
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Fallback: Copying text command was ' + msg);
    } catch (err) {
      console.error('Fallback: Oops, unable to copy', err);
    }

    document.body.removeChild(textArea);
    return successful
}

export const getDateString = (Date) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
    var dd = String(Date.getDate()).padStart(2, '0');
    var month = months[Date.getMonth()]; //January is 0!
    var yyyy = Date.getFullYear();

    return month + ' ' + dd + ', ' + yyyy;
}

export const slugify = (text) => {
    return text
  .toString()
  .normalize('NFD')
  .replace(/[\u0300-\u036f]/g, '')
  .toLowerCase()
  .trim()
  .replace(/\s+/g, '-')
  .replace(/[^\w-]+/g, '')
  .replace(/--+/g, '-');
}

export const getUrlParameters = () => {
    var sPageURL = decodeURIComponent(window.location.search.substring(1));
    var object = {}
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        const [key, value] = sURLVariables[i].split('=');
        if (value) {
            object[key] = value
        }
    }
    return object;
}

export const addToQueryString = (queryKey, queryValue) => {
    const queries = getUrlParameters()
    const string = Object.keys(queries).filter(key => key !== queryKey).map((key) => `&${key}=${queries[key]}`).join('') || ''
    return `?${queryKey}=${queryValue}${string}`
}

export const shallowEqual = (object1, object2) => {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) {
      return false;
    }
    for (let key of keys1) {
      if (object1[key] !== object2[key]) {
        return false;
      }
    }
    return true;
}
