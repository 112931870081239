import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"

import Header from "components/Header"
import Footer from 'components/Footer'

import HomePage from 'pages/home'
import PressPage from 'pages/press'
import AboutPage from 'pages/about'

function App() {
  return (
    <Router basename={process.env.PUBLIC_URL}>
      <Header/>
      <main className="">
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route exact path="/press" component={PressPage} />
          <Route exact path="/about" component={AboutPage} />
          <Redirect from="*" to="/" />
        </Switch>
      </main>
      <Footer/>
    </Router>
  );
}

export default App;
