import { useState, useEffect, useRef } from "react"

export default function FadeInSection({children, className, threshold = 0.0}) {
    const [isVisible, setVisible] = useState(false)
    const domRef = useRef()

    useEffect(() => {      
        let observerRefValue = null;
      
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => setVisible(entry.isIntersecting))
        }, {threshold})
      
        if (domRef.current) {
          observer.observe(domRef.current);
          observerRefValue = domRef.current;
        }
      
        return () => {
          if (observerRefValue) observer.unobserve(observerRefValue);
        };
    }, [domRef, threshold]);

    useEffect(() => {
        function onScroll() {
            if (domRef.current) {
                let bodyRect = document.body.getBoundingClientRect(),
                elemRect = domRef.current.getBoundingClientRect(),
                offset = elemRect.top - bodyRect.top;
                if (window.pageYOffset >= offset) {
                    setVisible(true)
                }
            }
        }

        window.addEventListener('scroll', onScroll)

        return () => window.removeEventListener('scroll', onScroll)
    }, [domRef])

    return (
        <div className={`fade-in-section ${isVisible ? 'is-visible' : ''} ${className || ''}`} ref={domRef}>
            {children}
        </div>
    )
}
