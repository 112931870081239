import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

export default function OurStory({title, story, statistics}) {
    return (
        <section id="About Us" className="our-story">
            <div className="our-story__title">
                <hr className="about-hr"/>
                <div className="title">{title}</div>
            </div>
            <div className="our-story__story">
                {documentToReactComponents(story)}
            </div>
            <div className="our-story__stats">
                {statistics.map(({fields, sys}) => (
                    <div className="our-story__stat" key={`story-stat-${sys.id}`}>
                        <div className="stat-big-text-container">
                            <div className={`stat-big-text ${fields.statisticBigText.length < 5 ? 'stat-number' : ''}`}>
                                {fields.statisticBigText}
                                {fields.includePlusSign && <span>+</span>}
                            </div>
                        </div>
                        <div className="stat-caption">
                            {fields.caption}
                        </div>
                    </div>
                ))}
            </div>
        </section>
    )
}
