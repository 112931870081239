import { fetchHomePage } from 'helpers/newsroom'
import { useQuery } from 'react-query'

import HomeHero from 'components/home/HomeHero'
import RecentNews from 'components/home/RecentNews'
import AboutMC from 'components/home/AboutMC'


export default function HomePage() {
    const { isLoading, isError, data, error } = useQuery('landing-page', fetchHomePage)

    if (isLoading) return null

    if (isError) {
        console.error(error.message)
    }

    return (
        <div className="home">
            <HomeHero heroArticle={data.heroArticle}/>
            <RecentNews articles={data.featuredArticles}/>
            <AboutMC collageStatistic={data.collageStatistic} collageImages={data.collageImages} quote={data.quote}/>
            <section className="home__links">
                <div className="container content-grid">
                    <div className="home__media-kit">
                        <h5>Media Kit</h5>
                        <p>Journalists can find access to images, video, logos and more by downloading the kit right here.</p>
                        <a className="btn btn-primary" href="https://brandfolder.com/portals/mc-media-kit" target="_blank" rel="noreferrer">Download Assets</a>
                    </div>
                    <div className="home__reach-out">
                        <h5>Reach Out</h5>
                        <p>Have a question? Feel free to reach out to someone from our press team, and we’ll get back to you promptly. </p>
                        <a href="mailto:press@masterclass.com" className="btn">press@masterclass.com</a>
                    </div>
                </div>
            </section>
        </div>
    )
}
