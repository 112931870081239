import { Link } from 'react-router-dom'
import ArticleHalfCard from "components/ArticleHalfCard"

export default function RecentNews({articles}) {
    return (
        <section className="home__recent-news">
            <div className="container home__recent-news-grid">
                { articles.map(article => <ArticleHalfCard key={article.id} article={article}/> )}
            </div>
            <div className="home__recent-news-button-row">
                <Link to="/press">
                    <button className="btn btn-primary">Read More Press</button>
                </Link>
            </div>
        </section>
    )
}
