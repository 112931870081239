export default function Contact({reachOutText, reachOutLink}) {
    return (
        <section id="Contact" className="contact">
            <hr/>
            <h4 className="title">Reach Out</h4>
            <div className="content">
                <p>{reachOutText}</p>
                <a href={`mailto:${reachOutLink}`} className="btn btn-link lowercase">{reachOutLink}</a>
            </div>
        </section>
    )
}
