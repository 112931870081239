import {ReactComponent as TwitterIcon} from 'assets/svg/social_icons/twitter.svg'
import {ReactComponent as FacebookIcon} from 'assets/svg/social_icons/facebook.svg'
import {ReactComponent as YoutubeIcon} from 'assets/svg/social_icons/youtube.svg'
import {ReactComponent as InstagramIcon} from 'assets/svg/social_icons/instagram.svg'
import {ReactComponent as LinkedinIcon} from 'assets/svg/social_icons/linkedin.svg'

import {ReactComponent as AmazonStoreLogo} from 'assets/svg/download_logos/amazon-store.svg'
import {ReactComponent as AppStoreLogo} from 'assets/svg/download_logos/app-store.svg'
import {ReactComponent as GooglePlayStoreLogo} from 'assets/svg/download_logos/google-play-store.svg'
import {ReactComponent as RokuStoreLogo} from 'assets/svg/download_logos/roku-store.svg'

const globeIcon = <svg width="2em" height="2em" viewBox="0 0 24 24" fill="none" className="mc-mr-3"><path d="M12.018 4C16.424 4 20 7.576 20 12.018A7.984 7.984 0 0112.018 20C7.576 20 4 16.424 4 12.018A8 8 0 0112.018 4zm.361.722v4.804a9.792 9.792 0 002.998-.578c-.47-1.553-1.336-3.034-2.564-4.19-.145 0-.29-.036-.434-.036zm1.662.29a10.439 10.439 0 011.986 3.683 11.37 11.37 0 001.842-1.011c-.975-1.3-2.311-2.24-3.828-2.673zm4.225 3.25c-.614.47-1.3.83-2.022 1.156.18.83.289 1.697.289 2.6s-.109 1.77-.29 2.6a9.992 9.992 0 012.023 1.12 7.27 7.27 0 001.012-3.72c0-1.372-.361-2.673-1.012-3.756zm-.397 8.09a11.56 11.56 0 00-1.842-1.047c-.397 1.336-1.083 2.636-1.986 3.684a7.307 7.307 0 003.828-2.637zm-5.056 2.89a9.375 9.375 0 002.564-4.19c-1.011-.325-1.987-.542-2.998-.578v4.804c.145 0 .29 0 .434-.037zm-1.156.036v-4.804c-1.047.036-2.059.18-3.034.506.47 1.589 1.336 3.106 2.564 4.261.145.037.29.037.47.037zm-1.698-.29c-.939-1.083-1.589-2.383-2.022-3.755-.65.252-1.3.614-1.878 1.01.975 1.337 2.347 2.312 3.9 2.746zM5.698 15.63c.614-.434 1.336-.795 2.058-1.12a11.21 11.21 0 01-.289-2.492c0-.867.109-1.734.29-2.528a10.278 10.278 0 01-2.06-1.12 7.34 7.34 0 00-.975 3.648c0 1.3.362 2.528.976 3.612zm.36-7.838a9.273 9.273 0 001.879.975C8.37 7.395 9.02 6.095 9.959 5.011a7.542 7.542 0 00-3.9 2.781zm5.13-3.034C9.958 5.914 9.092 7.431 8.622 9.02a9.717 9.717 0 003.034.506V4.722c-.18 0-.325.036-.47.036zm1.191 5.49v3.504c1.084.036 2.131.252 3.179.614.144-.759.252-1.553.252-2.348 0-.83-.108-1.59-.252-2.348-1.048.325-2.095.542-3.179.578zm-3.937-.505c-.144.722-.252 1.48-.252 2.275 0 .758.108 1.517.252 2.275a11.745 11.745 0 013.215-.541v-3.504c-1.12 0-2.203-.18-3.215-.505z" fill="currentColor"></path></svg>
const downwardArrowIcon = <svg width="2em" height="2em" viewBox="0 0 24 24" fill="none" className="mc-ml-3"><path fillRule="evenodd" clipRule="evenodd" d="M5.47 8.845a.75.75 0 011.06 0l5.47 5.47 5.47-5.47a.75.75 0 111.06 1.06l-6 6a.75.75 0 01-1.06 0l-6-6a.75.75 0 010-1.06z" fill="currentColor"></path></svg>
const lockIcon = <svg width="2em" height="2em" viewBox="0 0 24 24" fill="none" className="mc-icon--3"><path d="M6.75 18v-6.375h10.5V18H6.75z" fill="currentColor"></path><path fillRule="evenodd" clipRule="evenodd" d="M6 11.625a.75.75 0 01.75-.75h10.5a.75.75 0 01.75.75V18a.75.75 0 01-.75.75H6.75A.75.75 0 016 18v-6.375zm1.5.75v4.875h9v-4.875h-9z" fill="currentColor"></path><path fillRule="evenodd" clipRule="evenodd" d="M12 6.75c-1.612 0-3 1.409-3 3.25v1.25H7.5V10c0-2.577 1.97-4.75 4.5-4.75 2.53 0 4.5 2.173 4.5 4.75v1.25H15V10c0-1.841-1.388-3.25-3-3.25z" fill="currentColor"></path></svg>

const exploreLinks = [
    { label: 'MasterClass Live', url: 'https://learn.masterclass.com/masterclasslive' },
    { label: 'Articles', url: 'https://www.masterclass.com/articles' },
    { label: 'Sitemap', url: 'https://www.masterclass.com/sitemap' },
    { label: 'Gifts', url: 'https://www.masterclass.com/gift' }
]

const aboutLinks = [
    { label: 'Diversity, Equity, and Inclusion', url: 'https://learn.masterclass.com/dei' },
    { label: 'Careers', url: 'https://careers.masterclass.com' },
    { label: 'Privacy', url: 'https://privacy.masterclass.com/' },
    { label: 'CA Privacy Notice', url: 'https://privacy.masterclass.com/policies?name=privacy-notice-for-ca-and-nv-residents' },
    { label: 'Do Not Sell My Personal Info', url: 'https://privacy.masterclass.com/policies?name=california-do-not-sell-notice' },
    { label: 'Terms', url: 'https://www.masterclass.com/terms' },
    { label: 'Grants', url: 'https://learn.masterclass.com/grants' },
    { label: 'For Businesses', url: 'https://masterclass.com/for-businesses' },
    { label: 'Support', url: 'https://masterclasshelp.zendesk.com/hc' }
]

const socialLinks = [
    { label: 'Twitter', icon: <TwitterIcon/>, url: "https://twitter.com/masterclass"},
    { label: 'Instagram', icon: <InstagramIcon/>, url: "https://www.instagram.com/masterclass"},
    { label: 'Facebook', icon: <FacebookIcon/>, url: "https://www.facebook.com/masterclassofficial/"},
    { label: 'YouTube', icon: <YoutubeIcon/>, url: "https://youtube.com/c/masterclass"},
    { label: 'LinkedIn', icon: <LinkedinIcon/>, url: "https://www.linkedin.com/company/masterclassinc"},
]

const downloadLinks = [
    { label: 'App Store Link', logo: <AppStoreLogo/>, url: "https://itunes.apple.com/US/app/id1273867416"},
    { label: 'Google Play Link', logo: <GooglePlayStoreLogo/>, url: "https://play.google.com/store/apps/details?id=com.yanka.mc"},
    { label: 'Amazon AppStore Link', logo: <AmazonStoreLogo/>, url: "https://www.amazon.com/MasterClass-Learn-from-the-best/dp/B081QX5R8T/ref=sr_1_1?keywords=masterclass&qid=1575500772&s=mobile-apps&sr=1-1"},
    { label: 'Roku Channel Link', logo: <RokuStoreLogo/>, url: "https://channelstore.roku.com/details/579323/masterclass-learn-new-skills"}
]

export default function Footer() {
    return (
        <footer className="mc-page__footer">
            <div>
                <div className="footer">
                    <hr className="mc-separator"/>
                    <div className="footer-container">
                        <div className="mc-my-10">
                            <div className="row">
                                <div className="col">
                                    <div className="mc-mb-6">
                                        <span className="mc-text-h7 mc-opacity--muted">Explore</span>
                                    </div>
                                    <div className="mc-text-small mc-mb-9">
                                        <ul>
                                            {
                                                exploreLinks.map(({label, url}, index) => (
                                                    <li className="mc-mb-5 mc-pb-1" key={`explore-links-${index + 1}`}>
                                                        <a href={url} className="mc-text--bare-link">{label}</a>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="mc-mb-6">
                                        <span className="mc-text-h7 mc-opacity--muted">About</span>
                                    </div>
                                    <div className="mc-text-small mc-mb-9">
                                        <ul>
                                            {
                                                aboutLinks.map(({label, url}, index) => (
                                                    <li className="mc-mb-5 mc-pb-1" key={`about-links-${index + 1}`}>
                                                        <a href={url} className="mc-text--bare-link">{label}</a>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="col d-none d-md-block">
                                    <div className="mc-mb-6">
                                        <span className="mc-text-h7 mc-opacity--muted">Social</span>
                                    </div>
                                    <div className="mc-text-small mc-mb-9">
                                        <ul>
                                            {
                                                socialLinks.map(({label, icon, url}, index) => (
                                                    <li className="mc-mb-4" key={`social-link-${index + 1}`}>
                                                        <a href={url} target="_blank" rel="noopener noreferrer">
                                                            { icon }
                                                            <span className="mc-text--bare-link">{label}</span>
                                                        </a>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                                <div className="col d-none d-md-block">
                                    <div className="mc-mb-6">
                                        <span className="mc-text-h7 mc-opacity--muted">Download</span>
                                    </div>
                                    <div className="mc-mb-9">
                                        <ul>
                                            {
                                                downloadLinks.map(({label, logo, url}) => (
                                                    <li className="mc-mb-4" key={label}>
                                                        <a href={url} target="_blank" rel="noopener noreferrer">
                                                            { logo }
                                                        </a>
                                                    </li>
                                                ))
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="row d-md-none mc-my-9 justify-content-center">
                                {
                                    [...socialLinks.slice(0, 1),
                                    ...socialLinks.slice(1, 3).reverse(),
                                    ...socialLinks.slice(3)].map(({icon, url}, index) => (
                                        <div className="col-2" key={`social-mobile-link-${index + 1}`}>
                                            <a href={url} target="_blank" rel="noopener noreferrer">
                                                {icon}
                                            </a>
                                        </div>
                                    ))
                                }
                            </div>
                            <div className="row d-md-none justify-content-center">
                                { downloadLinks[0] && <div className="mc-mr-2">
                                    <a href={downloadLinks[0].url} target="_blank" rel="noopener noreferrer">
                                        {downloadLinks[0].logo}
                                    </a>
                                </div> }
                                { downloadLinks[1] && <div className="mc-ml-2">
                                    <a href={downloadLinks[1].url} target="_blank" rel="noopener noreferrer">
                                        {downloadLinks[1].logo}
                                    </a>
                                </div>
                                }
                            </div>
                            <div className="row d-md-none mc-mt-4 justify-content-center">
                                { downloadLinks[2] && <div className="mc-mr-2">
                                    <a href={downloadLinks[2].url} target="_blank" rel="noopener noreferrer">
                                        {downloadLinks[2].logo}
                                    </a>
                                </div> }
                                { downloadLinks[3] && <div className="mc-ml-2">
                                    <a href={downloadLinks[3].url} target="_blank" rel="noopener noreferrer">
                                        {downloadLinks[3].logo}
                                    </a>
                                </div>
                                }
                            </div>
                        </div>
                        <div className="mc-my-3">
                            <div>
                                <button className="c-button c-button--tertiary c-button--md  ">
                                    {globeIcon}
                                    {"English (US)"}
                                    {downwardArrowIcon}
                                </button>
                            </div>
                        </div>
                        <div className="row d-none d-md-block">
                            <div className="col">
                                <div className="d-inline-flex align-items-center mc-my-4">
                                    <div className="mc-pr-4">
                                        <a href="/">
                                            <svg width="2em" height="2em" viewBox="0 0 24 24" fill="none" className="mc-icon">
                                            <path d="M11.843 12.6L9.775 5H4.033v1.22h.545c.68 0 1.242.421 1.45 1.199l3.162 11.58h2.88l1.412-5.14-.02-.019c-.921 0-1.375-.36-1.619-1.24zM23.455 17.779c-.677 0-1.223-.48-1.43-1.22L18.865 5h-4.405l3.82 13.999H24v-1.22h-.545zM0 17.776V19h5.088v-1.224H0z" fill="currentColor"/>
                                            </svg>
                                        </a>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <span className="mc-text-small mc-opacity--muted">© 2021 MasterClass</span>
                                    </div>
                                    <div className="mc-mx-4 mc-text-small mc-opacity--muted d-flex align-items-center">
                                        {lockIcon}
                                        <span>Secured with SSL</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="d-md-none mc-my-4">
                            <div className="row justify-content-center">
                                <div className="col-auto">
                                    <a href="/">
                                        <img className="mc-mb-4" src="https://www.masterclass.com/webpack/_/logo-b88930ab50233ee0b15abcf187e0bcc6.svg" alt="MasterClass logo" style={{ width: '88px'}}/>
                                    </a>
                                </div>
                            </div>
                            <div className="mc-text--center mc-text-small mc-opacity--muted">
                                <p><span>© 2021 MasterClass</span></p>
                                <p className="mc-mt-2">
                                    {lockIcon}
                                    <span>Secured with SSL</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
