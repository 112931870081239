import arrowUp from 'assets/svg/arrow-up.svg'

export default function ArticleHero({article}) {
    return (
        <div className="article-hero">
            <img alt={article?.title} src={article?.imageUrl} onClick={() => window.open(article.linkUrl, '_blank').focus()} />
            <div className="gradient" onClick={() => window.open(article.linkUrl, '_blank').focus()}/>
            <div className="article-content">
                <h4 className="article-hero-title news-title-28" onClick={() => window.open(article.linkUrl, '_blank').focus()}>{article?.title}</h4>
                <a target="_blank" rel="noreferrer" href={article?.linkUrl} className="news-link red-underline muted-link">{article?.linkPublisher} <img alt="Arrow" className="link-arrow" src={arrowUp}/></a>
            </div>
        </div>
    )
}
