import { useQueries } from 'react-query'
import { withRouter } from 'react-router-dom'
import useRouterQuery from 'hooks/useRouterQuery'
import { fetchArticles } from 'helpers/newsroom'

import ArticleThirdCard from 'components/ArticleThirdCard'
import ArticlePressRelease from 'components/ArticlePressRelease'
import PressBar from 'components/press/PressBar'
import PressResults from 'components/press/PressResults'

function PressPage() {
    const RouterQuery = useRouterQuery()
    const isPressLandingPage = !RouterQuery.get('articleType') && !RouterQuery.get('articleTopic') && !RouterQuery.get('articleDate') && !RouterQuery.get('search')

    const featuredArticlesFilter = {
        articleType: 'Featured Articles',
        articleTopic: 'All Topics',
        articleDate: 'Latest'
    }

    const pressReleasesFilter = {
        articleType: 'Press Releases',
        articleTopic: 'All Topics',
        articleDate: 'Latest'
    }

    const [pressHighlights, pressReleases] = useQueries([
        {queryKey: ['pressHighlights'], queryFn: () => fetchArticles(featuredArticlesFilter, '', 0, 12)},
        {queryKey: ['pressReleases'], queryFn: () => fetchArticles(pressReleasesFilter, '', 0, 6)}
    ])

    if (isPressLandingPage) {

        if (pressHighlights.isError) {
            console.error('Press Highlights:', pressHighlights.error)
        }

        if (pressReleases.isError) {
            console.error('Press Highlights:', pressReleases.error)
        }

        if (pressHighlights.isLoading || pressReleases.isLoading) return null


        return (
            <div className="press">
                <div className="container">
                    <h2 className="press__hero">MasterClass in the Press</h2>
                </div>
                <PressBar/>
                <section className="container press__articles">
                    <div className="press__articles-highlights">
                        <hr/>
                        <h5 className="press__articles-highlights-header">Press Highlights</h5>
                    </div>
                    <div className="grid">
                        {pressHighlights.data && pressHighlights.data.map((article, i) => (
                            <ArticleThirdCard key={`press-highlight-${i + 1}`} article={article} />
                        ))}
                    </div>
                </section>
                <section className="press__releases">
                    <div className="container">
                        <div className="press__releases-header">
                            <hr/>
                            <h5 className="press__releases-header-text">Press Releases</h5>
                        </div>
                        <div className="press__releases-grid">
                            {pressReleases.data && pressReleases.data.map((article, i) => (
                                <ArticlePressRelease key={`press-release-${i + 1}`} article={article}/>
                            ))}
                        </div>
                    </div>
                </section>
            </div>
        )
    }

    return (
        <PressResults />
    )
}

export default withRouter(PressPage)
