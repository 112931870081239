import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import useRouterQuery from 'hooks/useRouterQuery'
import {fetchTopics, fetchArticleYears} from 'helpers/newsroom'
import { addToQueryString } from 'utils'

import MobileMenu from 'components/MobileMenu'
import SearchForm from "./SearchForm"
import searchGlassIcon from 'assets/svg/search-glass.svg'

const articleTypes = ['Featured Articles', 'Press Coverage', 'Press Releases', 'All Articles']

function PressBar() {
    const { data: articleDates } = useQuery(['articleDates'], fetchArticleYears)
    const { isLoading, isError, data: articleTopics, error } = useQuery(['articleTopics'], fetchTopics)

    const RouterQuery = useRouterQuery()
    const articleTypeQuery = RouterQuery.get('articleType')
    const articleTopicQuery = RouterQuery.get('articleTopic')
    const articleDateQuery = RouterQuery.get('articleDate')

    const pressBarRef = useRef()

    const articlesFilterRef = useRef()
    const topicsFilterRef = useRef()
    const datesFilterRef = useRef()
    const mobileArticlesFilterRef = useRef()
    const mobileTopicsFilterRef = useRef()
    const mobileDatesFilterRef = useRef()

    const [filterMenu, setFilterMenu] = useState(null)
    const [menuOpen, setMenuOpen] = useState(false)

    useEffect(() => {
        function checkResize() {
            if (window.innerWidth >= 768) {
                setFilterMenu(null)
            }
        }

        const element = pressBarRef?.current
        const observer = new IntersectionObserver(
            ([e]) => {
            e.target.classList.toggle('is-pinned', e.intersectionRatio < 1)
            },
            {threshold: [1]}
        )

        if(element) {
            observer.observe(element)
        }

        window.addEventListener('resize', checkResize)

        return (() => {
            if (element) {
                observer.unobserve(element)
            }
            window.removeEventListener('resize', checkResize)
        })
    }, [])

    useEffect(() => {
        function handleOutsideClicks(e) {
            switch(e.target) {
                case articlesFilterRef.current:
                case mobileArticlesFilterRef.current:
                    setFilterMenu(state => state !== 'articleTypes' ? 'articleTypes' : null)
                    break;
                case topicsFilterRef.current:
                case mobileTopicsFilterRef.current:
                    setFilterMenu(state => state !== 'articleTopics' ? 'articleTopics' : null)
                    break;
                case datesFilterRef.current:
                case mobileDatesFilterRef.current:
                    setFilterMenu(state => state !== 'articleDates' ? 'articleDates' : null)
                    break;
                default:
                    setFilterMenu(null)
            }
        }

        function handlePressBarScroll() {
            const header = document.querySelector('header')
            const wrapper = document.querySelector('.press__bar-wrapper')

            if (header && wrapper) {
                if (header.classList.contains('hide')) {
                    wrapper.classList?.remove('is-navbar')
                } else {
                    wrapper.classList?.add('is-navbar')
                }
            }
        }

        document.body.addEventListener('click', handleOutsideClicks)
        window.addEventListener('scroll', handlePressBarScroll)

        handlePressBarScroll()

        return () => {
            document.body.removeEventListener('click', handleOutsideClicks)
            window.removeEventListener('scroll', handlePressBarScroll)
        }
    }, [])

    if (isError) {
        console.error(error.message)
    }

    const currentArticleType = useMemo(() => {
        if (articleTypes) {
            return articleTypeQuery ? (articleTypes.find(type => type === articleTypeQuery) || 'All Articles') : 'Featured Articles'
        }

        return null
    }, [articleTypeQuery])

    const currentArticleTopic = useMemo(() => {
        if (articleTopics) {
            return articleTopicQuery ? (articleTopics.find(topic => topic === articleTopicQuery) || 'All Topics') : 'All Topics'
        }

        return null
    }, [articleTopicQuery, articleTopics])

    const currentArticleDate = useMemo(() => {
        if (articleDates) {
            return articleDateQuery ? (articleDates.find(date => date.toString() === articleDateQuery) || 'Latest') : 'Latest'
        }

        return null
    }, [articleDateQuery, articleDates])

    return (
        <>
        <div className="press__bar" ref={pressBarRef}>
            {!isLoading && (
            <div className="press__bar-wrapper">
                <div className="container flex-wrapper">
                    <div className="press__filters desktop-and-tablet-only">
                        <div className="filter">
                            <span ref={articlesFilterRef}>{currentArticleType} {filterMenu === 'articleTypes' ? '-' : '+'}</span>
                            { filterMenu === 'articleTypes' && (
                                <div className="filter__dropdown">
                                    <ul className="filter__dropdown-container">
                                        {articleTypes.map(type => <li key={type}>
                                            <Link to={{search: addToQueryString('articleType', type)}}>
                                                {type}
                                            </Link>
                                        </li>)}
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className="filter">
                            <span ref={topicsFilterRef}>{currentArticleTopic} {filterMenu === 'articleTopics' ? '-' : '+'}</span>
                            { filterMenu === 'articleTopics' && (
                                <div className="filter__dropdown">
                                    <ul className="filter__dropdown-container">
                                        {articleTopics.map(topic => <li key={topic}>
                                            <Link to={{search: addToQueryString('articleTopic', topic)}}>
                                                {topic}
                                            </Link>
                                        </li>)}
                                        <li>
                                            <Link to={{search: addToQueryString('articleTopic', 'All Topics')}}>
                                                All Topics
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                        <div className="filter">
                            <span ref={datesFilterRef}>{currentArticleDate} {filterMenu === 'date' ? '-' : '+'}</span>
                            { filterMenu === 'articleDates' && (
                                <div className="filter__dropdown">
                                    <ul className="filter__dropdown-container">
                                        <li>
                                            <Link to={{search: addToQueryString('articleDate', 'Latest')}}>
                                                Latest
                                            </Link>
                                        </li>
                                        {articleDates.map(year => <li key={year}>
                                            <Link to={{search: addToQueryString('articleDate', year.toString())}}>
                                                {year}
                                            </Link>
                                        </li>)}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="press__search desktop-and-tablet-only">
                        <SearchForm/>
                    </div>

                    <div className="press__filters mobile-only">
                        <div className="filter">
                            <span onClick={() => setMenuOpen(true)}>Filter +</span>
                        </div>
                    </div>
                    <div className="press__search mobile-only">
                        <img onClick={() => setMenuOpen(true)} alt="Search" src={searchGlassIcon}/>
                    </div>
                </div>
            </div>
            )}
        </div>
        <MobileMenu menuOpen={menuOpen} setMenuOpen={setMenuOpen}>
            <div className="press__mobile">
                <div className="press__search__mobile">
                    { menuOpen && <SearchForm/> }
                </div>
                <div className="press__filters__mobile">
                    <div className="filter">
                        <span ref={mobileArticlesFilterRef}>{currentArticleType} {filterMenu === 'articleTypes' ? '-' : '+'}</span>
                        { filterMenu === 'articleTypes' && (
                            <div className="filter__dropdown">
                                <ul className="filter__dropdown-container">
                                    {articleTypes.map(type => <li key={type}>
                                        <Link to={{search: addToQueryString('articleType', type)}}>
                                            {type}
                                        </Link>
                                    </li>)}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="filter">
                        <span ref={mobileTopicsFilterRef}>{currentArticleTopic} {filterMenu === 'articleTopics' ? '-' : '+'}</span>
                        { filterMenu === 'articleTopics' && (
                            <div className="filter__dropdown">
                                <ul className="filter__dropdown-container">
                                    {articleTopics.map(topic => <li key={topic}>
                                        <Link to={{search: addToQueryString('articleTopic', topic)}}>
                                            {topic}
                                        </Link>
                                    </li>)}
                                    <li>
                                        <Link to={{search: addToQueryString('articleTopic', 'All Topics')}}>
                                            All Topics
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="filter">
                        <span ref={mobileDatesFilterRef}>{currentArticleDate} {filterMenu === 'date' ? '-' : '+'}</span>
                        { filterMenu === 'articleDates' && (
                            <div className="filter__dropdown">
                                <ul className="filter__dropdown-container">
                                    <li>
                                        <Link to={{search: addToQueryString('articleDate', 'Latest')}}>
                                            Latest
                                        </Link>
                                    </li>
                                    {articleDates.map(year => <li key={year}>
                                        <Link to={{search: addToQueryString('articleDate', year.toString())}}>
                                            {year}
                                        </Link>
                                    </li>)}
                                </ul>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </MobileMenu>
    </>
    )
}

export default PressBar
