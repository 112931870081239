import { useState, useEffect } from 'react'
import { createClient } from 'contentful'

export const client = createClient({
    space: process.env.REACT_APP_SPACE,
    accessToken: process.env.REACT_APP_SPACE_ACCESS_TOKEN
})

export default function ContentfulProvider({children}) {
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        (async () => {
            try {
                await client.getSpace()

                setLoading(false)
            } catch (err) {
                console.error('Invalid Contentful credientials.')
            }
        })()
    }, [])

    if (loading) return null

    return (
        <>
            {children}
        </>
    )
}