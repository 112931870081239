import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import mobileNavExitArrow from 'assets/img/mobile-nav-arrow.png'



export default function MobileMenu({children, menuOpen, setMenuOpen}) {
    const isInitialized = useRef()
    const containerRef = useRef()
    const location = useLocation()

    useEffect(() => {
        if (setMenuOpen) {
            function checkResize() {
                if (window.innerWidth >= 768) {
                    setMenuOpen(false)
                }
            }

            checkResize()
            window.addEventListener('resize', checkResize)

            return () => window.removeEventListener('resize', checkResize)
        }
    }, [setMenuOpen])

    useEffect(() => {
        setMenuOpen && setMenuOpen(false)
    }, [location, setMenuOpen])

    useEffect(() => {
        if (isInitialized.current) {
            if (menuOpen) {
                document.body.style.top = `-${window.scrollY}px`
                document.body.style.position = 'fixed';
            } else {
                if (!document.body.classList.contains('bio-popup')) {
                    const scrollY = document.body.style.top
                    document.body.style.position = '';
                    document.body.style.top = '';
                    window.scrollTo(0, parseInt(scrollY || '0') * -1)
                }
            }
        } else {
            isInitialized.current = true
        }
    }, [menuOpen])

    useEffect(() => {
        // if (location.search) return
        document.body.style.position = '';
        document.body.style.top = '';

        window.scrollTo(0, 0);
    }, [location])


    return (
        <div className={`mobile-menu ${menuOpen ? 'active' : ''}`} ref={containerRef}>
            <div className="mobile-menu__wrapper">
                <div className="nav-menu-exit" onClick={() => setMenuOpen(false)}>
                    <img alt="Exit" src={mobileNavExitArrow} />
                </div>
                {children}
            </div>
        </div>
    )
}
