import React, { useState, useEffect, useRef } from 'react'
import searchGlassIcon from 'assets/svg/search-glass.svg'
import { withRouter } from 'react-router-dom'
import { getUrlParameters } from 'utils'

import useDebounce from 'hooks/useDebounce'

function SearchForm({history}) {
    const initialized = useRef()
    const inputRef = useRef()
    const [inputSearch, setInputSearch] = useState()
    const debounceValue = useDebounce(inputSearch)

    useEffect(() => {
        const {search} = getUrlParameters()
        if (search && search !== '') {
            inputRef.current.value = search
        }
    }, [])

    const handleSearchForm = e => {
        e.preventDefault()
        history.push(`/press?search=${e.target.search.value}&articleType=All Articles`)
    }

    const handleSearchClearButton = () => {
        history.push(`/press?articleType=All Articles`)
    }

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            return;
        }

        if (window.innerWidth >= 768) {
            history.push(`/press?search=${inputSearch || ''}&articleType=All Articles`)
        }
        // eslint-disable-next-line
    }, [debounceValue])

    return (
        <form className="search-form" onSubmit={handleSearchForm}>
            <button type="submit"><img alt="Search" src={searchGlassIcon}/></button>
            <input
                ref={inputRef}
                name="search"
                type="search"
                autocomplete="off"
                placeholder="Search by instructor, keyword, etc"
                onChange={(e) => setInputSearch(e.target.value)}
            />
            <button className="search-close-icon" type="reset" onClick={handleSearchClearButton}></button>
        </form>
    )
}

export default withRouter(React.memo(SearchForm))
