import { useState, useEffect, useRef } from 'react'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { ReactComponent as Hamburger } from '../assets/svg/hamburger.svg'
import mcUnionLogo from '../assets/svg/mc-union-logo.svg'
import mobileNavExitArrow from 'assets/img/mobile-nav-arrow.png'

export default function Header() {
    const navbarRef = useRef()
    const location = useLocation()
    const history = useHistory()
    const [isHamburgerOpen, setHamburgerOpen] = useState(false)

    useEffect(() => {
        let previousPosition;

        function handleScroll() {
            if (previousPosition >= window.scrollY || window.scrollY === 0) {
                navbarRef.current.classList.remove('hide')
            } else {
                navbarRef.current.classList.add('hide')
            }

            previousPosition = window.scrollY
        }

        window.addEventListener('scroll', handleScroll)
        return () => window.removeEventListener('scroll', handleScroll)
    }, [])

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth >= 768) {
                setHamburgerOpen(false)
            }
        }

        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    useEffect(() => {
        setHamburgerOpen(false)
    }, [location])

    return (
        <>
        <header ref={navbarRef} className="header">
            <div className="container header__wrapper">
                <div className="header__logo" onClick={() => history.push('/')}>
                    <img className="logo" alt="MasterClass Logo" src="https://www.masterclass.com/webpack/_/mc-logo-937df31b02ee324a8e1fe1773969416e.svg" />
                    <img className="logo mobile" alt="MasterClass Logo" src={mcUnionLogo}/>
                    <span>Newsroom</span>
                </div>
                <nav>
                    <Hamburger onClick={() => setHamburgerOpen(state => !state)}/>
                    <ul>
                        <li>
                            <Link to="/about" className={`${location.pathname === '/about' ? 'active' : ''}`}>
                                About
                            </Link>
                        </li>
                        <li>
                            <Link to="/press" className={`${location.pathname === '/press' ? 'active' : ''}`}>
                                Press
                            </Link>
                        </li>
                        <li>
                            <a href="https://brandfolder.com/portals/mc-media-kit" target="_blank" rel="noreferrer">Media Kit</a>
                        </li>
                        <li>
                            <a href="mailto:press@masterclass.com">Contact</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
        <div className={`mobile-menu ${isHamburgerOpen ? 'active' : ''}`}>
            <div className="home mobile-menu__wrapper">
                <div className="nav-menu-exit" onClick={() => setHamburgerOpen(false)}>
                    <img alt="Exit" src={mobileNavExitArrow} />
                </div>
                <nav>
                    <ul>
                        <li>
                            <Link to="/about" className={`${location.pathname === '/about' ? 'active' : ''}`}>
                                About
                            </Link>
                        </li>
                        <li>
                            <Link to="/press" className={`${location.pathname === '/press' ? 'active' : ''}`}>
                                Press
                            </Link>
                        </li>
                        <li>
                            <a href="https://brandfolder.com/portals/mc-media-kit" target="_blank" rel="noreferrer">Media Kit</a>
                        </li>
                        <li>
                            <a href="mailto:press@masterclass.com">Contact</a>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
        </>
    )
}
