import arrowUp from 'assets/svg/arrow-up.svg'

export default function ArticlePressRelease({article}) {
    return (
        <div className="article-press-release">
            <div className="article-press-release__wrapper">
                <div className="article-press-release__image" onClick={() => window.open(article.linkUrl, '_blank').focus()}>
                    <img alt={article.title} src={article.imageUrl}/>
                </div>
                <div className="article-press-release__content">
                    <div className="article-press-release__eyebrow">
                        <span>{ article.topic }</span>
                        {' | '}
                        <span>{ article.date }</span>
                    </div>
                    <h5 className="article-press-release__headline">{ article.title }</h5>
                    <a href={article.linkUrl} target="_blank" rel="noreferrer" className="article-press-release__link news-link red-underline">{ article.linkPublisher } <img alt="Arrow" className="link-arrow" src={arrowUp}/></a>
                </div>
            </div>
        </div>
    )
}
