import { useHistory } from 'react-router-dom'
import FadeInSection from "components/FadeInSection"

export default function AboutMC({collageImages, collageStatistic, quote}) {
    const history = useHistory()

    return (
        <section className="home__about-mc">
            <div className="container">
                <div className="home__about-mc-top-section">
                    <div className="brief-history">
                        <hr/>
                        <h4 className="section-title">Our Past, Present, And Future</h4>
                        <FadeInSection threshold={0.8}>
                            <p>Since 2015, MasterClass has inspired a new society of knowledge-seekers.</p>
                        </FadeInSection>
                    </div>
                    <div className="users-worldwide">
                        <div>
                            { collageStatistic && (
                                <>
                                    <FadeInSection threshold={0.8}>
                                        <h4 className="counter">
                                            { collageStatistic.fields.statisticBigText }
                                            { collageStatistic.fields.includePlusSign && <span>+</span>}
                                        </h4>
                                    </FadeInSection>
                                    <FadeInSection threshold={0.8}>
                                        <h6>{ collageStatistic.fields.caption }</h6>
                                    </FadeInSection>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="home__about-mc-quote">
                {collageImages.map((image, i) => (
                        <FadeInSection threshold={0.8} key={`collage-image-${i + 1}`} className="collage-photo">
                            <img alt={image.fields.title} src={image.fields?.file.url}/>
                        </FadeInSection>
                    ))}
                {quote && (
                    <div className="quote-wrapper">
                        <FadeInSection threshold={0.8}>
                            <h4 className="quote red-quotations"><span className="startquote"/>{quote.text}<span className="endquote"/></h4>
                        </FadeInSection>
                        <FadeInSection threshold={0.8}>
                            <h6 className="quote-author">
                                    <span>
                                        {quote.author}
                                        {quote.authorOccupation && <span>,</span>}
                                    </span>
                                    {quote.authorOccupation}
                            </h6>
                        </FadeInSection>
                    </div>
                )}
                <FadeInSection threshold={0.7} className="learn-more">
                    <button className="btn btn-primary" onClick={() => history && history.push('/about')}>
                        Learn more about us
                    </button>
                </FadeInSection>
            </div>
        </section>
    )
}
