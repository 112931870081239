import { useState, useEffect, useRef } from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import { fallbackCopyTextToClipboard, forceDownload } from 'utils'

import {ReactComponent as SaveAltIcon} from 'assets/svg/save_alt.svg'
import {ReactComponent as ReceiptIcon} from 'assets/svg/receipt.svg'

export default function Leadership({team}) {
    const initializedSection = useRef(false)
    const [isBioShow, setBioShow] = useState(false)
    const [currentTeamMember, setCurrentTeamMember] = useState(null)

    useEffect(() => {
        if (initializedSection.current) {
            if (isBioShow) {
                document.body.classList.add('bio-popup')
                document.body.style.top = `-${window.scrollY}px`
                document.body.style.position = 'fixed';
            } else {
                document.body.classList.remove('bio-popup')
                const scrollY = document.body.style.top
                document.body.style.position = '';
                document.body.style.top = '';
                window.scrollTo(0, parseInt(scrollY || '0') * -1)
            }
        } else {
            initializedSection.current = true;
        }
    }, [isBioShow])

    const handleBioPopup = (id) => {
        if (!isBioShow && id) {
            const teamMember = team.find(person => person.id === id)
            setCurrentTeamMember(teamMember)
            setBioShow(true)
        } else {
            setBioShow(false)
            setCurrentTeamMember(null)
        }

    }

    return (
        <>
            <section id="Leadership" className="leadership">
                <div className="leadership__title">
                    <hr className="leadership-hr"/>
                    <div className="title">Meet Our Leadership Team</div>
                </div>
                <div className="leadership__description">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.
                </div>
                <div className="leadership__team">
                    {team.map((member) => (
                        <div key={member.id} className="leadership__member">
                            <div className="member-headshot" onClick={() => handleBioPopup(member.id)}>
                                <img alt={member.headshot.fields?.title} src={member.headshot.fields.file.url}/>
                            </div>
                            <div className="member-name">{member.name}</div>
                            <div className="member-position">{member.position}</div>
                            <div className="member-links">
                                <div className="member-links__learn" onClick={() => handleBioPopup(member.id)}>
                                    <span className="link-underline">Learn More</span>
                                    <span className="member-links__learn-plus">+</span>
                                </div>
                                <span className="member-links__separator">|</span>
                                <div className="member-links__download" onClick={() => forceDownload(member.headshot.fields.file.url, member.headshot.fields.file.url.substring(member.headshot.fields.file.url.lastIndexOf('/') + 1))}>
                                    <span className="link-underline">Download Headshot</span>
                                    <SaveAltIcon/>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            { isBioShow && (
                <div className="bio" onClick={handleBioPopup}>
                    <div className="blur-overlay"></div>
                    <div className="bio-popup">
                        <div className="bio-popup__container" onClick={(e) => e.stopPropagation()}>
                            <div className="bio-popup__wrapper">
                                <div className="bio-popup__headshot">
                                    <img alt={currentTeamMember.headshot.fields?.title} src={currentTeamMember.headshot.fields.file.url} />
                                    <div className="bio-popup__exit" onClick={handleBioPopup}></div>
                                </div>
                                <div className="bio-popup__content">
                                    <div className="bio-popup__content-wrapper">
                                        <h5 className="member-name">{currentTeamMember.name}</h5>
                                        <h6 className="member-position">{currentTeamMember.position}</h6>
                                        <div className="bio-summary">
                                            { documentToReactComponents(currentTeamMember.bio) }
                                        </div>
                                        <div className="bio-buttons">
                                            <div className="bio-buttons__copy" onClick={() => {
                                                const context = document.querySelector('.bio-popup__content .bio-summary')
                                                if (context && context.innerText) {
                                                    if(fallbackCopyTextToClipboard(context.innerText)) {
                                                        const button = document.body.querySelector('.bio-buttons__copy')
                                                        const messageBox = document.createElement("span");
                                                        messageBox.innerText = 'Copied'
                                                        messageBox.classList.add('copied')
                                                        button.appendChild(messageBox)
                                                        setTimeout(() => button.removeChild(messageBox), 500)
                                                    }
                                                }
                                            }}>
                                                <ReceiptIcon/>
                                                <span className="link-underline">Copy Bio</span>
                                            </div>
                                            <div className="bio-buttons__download" onClick={() => forceDownload(currentTeamMember.headshot.fields.file.url, currentTeamMember.headshot.fields.file.url.substring(currentTeamMember.headshot.fields.file.url.lastIndexOf('/') + 1))}>
                                                <SaveAltIcon/>
                                                <span className="link-underline">Download Headshot</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
