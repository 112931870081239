import ArticleHero from "components/ArticleHero"

export default function HomeHero({heroArticle}) {
    return (
        <section className="home__hero">
            <div className="container">
                <div className="home__hero-header">
                    <h1>MasterClass <br/>Happening Now</h1>
                </div>
                <div className="home__hero-grid">
                    <div className="home__hero-copy">
                        <hr/>
                        <p className="muted">Quickly find everything about who we are,  where we’re headed, and what people are saying.</p>
                    </div>
                    <div className="desktop-and-tablet-only home__hero-featured-article">
                        { heroArticle && 
                            <ArticleHero article={heroArticle} />
                        }
                    </div>
                </div>
            </div>
            <div className="mobile-only home__hero-featured-article">
                { heroArticle && 
                    <ArticleHero article={heroArticle} />
                }
            </div>
        </section>
    )
}
