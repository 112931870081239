import React, { useState, useEffect, useRef } from 'react'
import { fetchAboutPage } from 'helpers/newsroom'
import { withRouter } from 'react-router-dom'
import { useQuery } from 'react-query'

import heroImage from 'assets/img/about-mc-hero.png'

import OurStory from 'components/about/OurStory'
import Leadership from 'components/about/Leadership'
import Recognition from 'components/about/Recognition'
import Contact from 'components/about/Contact'

const AboutPage = () => {
    const mobileNavRef = useRef()
    const [anchorIndex, setAnchorIndex] = useState(-1)

    useEffect(() => {
        function handleNavScroll() {
            const sections = document.body.querySelectorAll('.mc-about section')
            if (sections) {
                sections.forEach((section, index) => {
                    if(window.pageYOffset >= section.offsetTop - 70) {
                        setAnchorIndex(index)
                    }
                })
            }
        }

        handleNavScroll()
        window.addEventListener('scroll', handleNavScroll)

        return () => {
            window.removeEventListener('scroll', handleNavScroll)
        }
    }, [])

    const { isLoading, isError, data, error }  = useQuery('aboutPageContent', fetchAboutPage)

    if (isLoading) return null

    if (isError) {
        console.error(error.message)
    }

    const content = [
        { anchor: "About Us", component: <OurStory title={data.ourStory.fields.title} story={data.ourStory.fields.story} statistics={data.ourStory.fields.statistics} />},
        { anchor: "Leadership", component: <Leadership team={data.leadershipTeam}/>},
        { anchor: "Recognition", component: <Recognition awards={data.awards}/>},
        { anchor: "Contact", component: <Contact reachOutText={data.contact.reachOutText} reachOutLink={data.contact.reachOutLink} />},
    ]

    return (
        <div className="mc-about">
            <div className="mc-about__hero">
                <div className="container container-fluid-xs">
                    <div className="mc-about__hero-wrapper">
                        <h2 className="mc-about__hero-title">Get To Know Us</h2>
                        <div className="mc-about__hero-image">
                            <img alt="Hero" src={heroImage}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mc-about__mobile-nav">
                <div className="mc-about__mobile-nav-wrapper" ref={mobileNavRef}>
                    <ul>
                        {
                            content.map(({anchor}, index) => (
                                <li key={anchor}>
                                    <button className={`about-nav-btn ${anchorIndex === index ? 'active': ''}`} onClick={(e) => {
                                    document.getElementById(anchor).scrollIntoView()
                                }}>
                                        {anchor}
                                    </button>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
            <div className="mc-about__content">
                <div className="container">
                    <div className="mc-about__grid">
                        <div className="mc-about__nav">
                            <ul>
                                {
                                    content.map(({anchor}, index) => (
                                        <li key={anchor}>
                                            <button className={`about-nav-btn ${anchorIndex === index ? 'active': ''}`} onClick={(e) => {
                                            document.getElementById(anchor).scrollIntoView()
                                        }}>
                                                {anchor}
                                            </button>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                        <div className="mc-about__content">
                            {content.map(({component}, index) => (
                                <React.Fragment key={`about-section-${index + 1}`}>
                                    {component}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(AboutPage)
